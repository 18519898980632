import React, { useState, useEffect } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar'
import { Card } from 'primereact/card'
import { Message } from 'primereact/message';
import useAxios from '../../hooks/useAxios'
import { useAuth } from '../../contexts/AuthContext'

const Usrinf = () => {
  const { user } = useAuth();

  const { fetchData, response, loading } = useAxios()
  const [params, setParams] = useState({ key: "msisdn", value: 992 })
  const [responseData, setResponseData] = useState(null)

  const handleInputChange = (e) => {
    if (e.value === null || e.value.toString().length === 3 && e.value.toString().substr(0, 3) != 992) {
      setParams({ ...params, value: 992 })
    } else {
      setParams({ ...params, value: e.value })
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setResponseData(null)
    fetchData({ method: 'get', url: "/api/usrinf", params: params })
  }

  useEffect(() => {
    if (response) {     
      setResponseData(response)
    }
  }, [response])


  if (!user) return null

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6">
            <InputNumber value={params.value} pattern='^[992]{3}[0-9]{9}$' onChange={handleInputChange} disabled={loading} placeholder="992XXXXXXXXX" useGrouping={false} />
          </div>
          <div className="col-12 md:col-6">
            <Button label="Поиск" className="p-button-info w-full" disabled={params.value.toString().length !== 12 || loading} raised />
          </div>
        </div>
      </form>
      {responseData?.result === "fail" && <div className='p-fluid mt-4'><Message severity="error" text={responseData.msg}></Message></div>}
      {loading && <div className="mt-6"><ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar></div >}

      {responseData?.data &&
        <Card className='mt-5'>
          {Object.keys(responseData.data).map((key) => (
            <div key={key}>{key}: {responseData.data[key]}</div>
          ))}
        </Card>
      }
    </>
  )
}

export default Usrinf