import { React, useState, useEffect } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { ProgressSpinner } from 'primereact/progressspinner'

import StaffCard from '../../components/staff/StaffCard'
import StaffTable from '../../components/staff/StaffTable';
import useAxios from '../../hooks/useAxios';

const Staff = () => {
  const [employees, setEmployees] = useState([]);
  const [checked, setChecked] = useState(true);
  const [departments, setDepartments] = useState([])
  const { response, error, setError, loading, fetchData } = useAxios();


  useEffect(() => {
    fetchData({ method: 'get', url: '/api/staff' })

  }, [])


  useEffect(() => {
    if (response !== null) {
      setEmployees(response.data)

      const deps = response.data.map(row => row.department).sort()
      setDepartments(Array.from(new Set(deps)))
    }
  }, [response]);




  /* return (
    <>
          {checked && employees ? <StaffCard employees={employees}></StaffCard> : <StaffTable employees={employees}></StaffTable>}
  
    </>
  ) */

  return (
    <>
      <div className="mt-2">
        <ToggleButton checked={checked} onChange={(e) => setChecked(e.value)} onLabel="Card" offLabel="Table" tooltip="Toggle view" />
      </div>

      {loading && <div align="center"><ProgressSpinner /></div>}
      {checked && employees ? <StaffCard employees={employees}></StaffCard> : <StaffTable employees={employees} departments={departments}></StaffTable>}


    </>
  )
};

export default Staff;