import { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import useAxios from '../../hooks/useAxios'
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';


const BtsDown = () => {
  const { fetchData, response } = useAxios()
  const [apiData, setApiData] = useState({})
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData({ method: 'get', url: '/api/bts_down' })
    setInterval(() => {
      fetchData({ method: 'get', url: '/api/bts_down' })
    }, 60000)
  }, [])


  useEffect(() => {
    if (response !== null) {
      setApiData(response)
      setLoading(false)
    }
  }, [response])

  useEffect(() => {
    if (apiData.status === "success") {
      const btsDownTotal = apiData.data.length
      const btsDownPowerCnt = Array.from(apiData.data).filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length
      const btsDownOtherCnt = btsDownTotal - btsDownPowerCnt

      const dushanbeDown = Array.from(apiData.data).filter((row => row.region === "Душанбе"))
      const dushanbeDownPowerCnt = dushanbeDown.filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length

      const rrpDown = Array.from(apiData.data).filter((row => row.region === "РРП"))
      const rrpDownPowerCnt = rrpDown.filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length

      const sogdDown = Array.from(apiData.data).filter((row => row.region === "Согд"))
      const sogdDownPowerCnt = sogdDown.filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length

      const kurganDown = Array.from(apiData.data).filter((row => row.region === "Хатлон (Курган-Тюбе)"))
      const kurganDownPowerCnt = kurganDown.filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length

      const kulyabDown = Array.from(apiData.data).filter((row => row.region === "Хатлон (Куляб)"))
      const kulyabDownPowerCnt = kulyabDown.filter((row => row.description && row.description.includes("Из-за отсутствия электропитания"))).length

      const summaryTable = [
        { region: "Душанбе", total: dushanbeDown.length, power: dushanbeDownPowerCnt },
        { region: "РРП", total: rrpDown.length, power: rrpDownPowerCnt },
        { region: "Согд", total: sogdDown.length, power: sogdDownPowerCnt },
        { region: "Хатлон (Курган-Тюбе)", total: kurganDown.length, power: kurganDownPowerCnt },
        { region: "Хатлон (Куляб)", total: kulyabDown.length, power: kulyabDownPowerCnt },
        { region: "Всего", total: btsDownTotal, power: btsDownPowerCnt },
      ]

      const documentStyle = getComputedStyle(document.documentElement);

      const data = {
        labels: [`Из-за отсутствия электропитания - ${btsDownPowerCnt}`, `По остальным причинам - ${btsDownOtherCnt}`],
        datasets: [
          {
            data: [btsDownPowerCnt, btsDownOtherCnt],
            backgroundColor: [
              documentStyle.getPropertyValue('--blue-500'),
              documentStyle.getPropertyValue('--yellow-500'),
            ],
            hoverBackgroundColor: [
              documentStyle.getPropertyValue('--blue-400'),
              documentStyle.getPropertyValue('--yellow-400'),
            ]
          }
        ]
      }

      const label = `Не работающие 2G/3G БС: ${btsDownTotal}`
      const options = {
        cutout: '60%',
        plugins: {
          legend: {
            position: "bottom"
          }
        }
      }
      setChartData(chartData => ({ ...chartData, data: data, options: options, summaryTable: summaryTable, label: label }))
    }
  }, [apiData])

  if (apiData.status === "error") return (
    <div className='p-fluid mt-2 '>
      <Message severity='error' text={apiData.msg} />
    </div>
  )

  const summaryTableTotalBodyTemplate = (rowData) => {
    const summaryTableTotalClassName = classNames('border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-teal-100 text-teal-900': rowData.total <= 5 && rowData.region !== "Всего",
      'bg-blue-100 text-blue-900': rowData.total > 5 && rowData.total < 10 && rowData.region !== "Всего",
      'bg-red-100 text-red-900': rowData.total >= 10 && rowData.region !== "Всего"
    });
    return <div className={summaryTableTotalClassName}>{rowData.total}</div>;
  };

  const summaryTablePowerBodyTemplate = (rowData) => {
    const summaryTableTotalClassName = classNames('border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-teal-100 text-teal-900': rowData.power <= 5 && rowData.region !== "Всего",
      'bg-blue-100 text-blue-900': rowData.power > 5 && rowData.power < 10 && rowData.region !== "Всего",
      'bg-red-100 text-red-900': rowData.power >= 10 && rowData.region !== "Всего"
    });
    return <div className={summaryTableTotalClassName}>{rowData.power}</div>;
  };


  const btsBodyTemplate = (rowData) => {
    const btsBodyTemplateClassName = classNames('font-bold text-sm', {
      'text-surface-400': rowData.color === "gray",
      'text-green-900': rowData.color === "green",
      'text-blue-900': rowData.color === "blue",
      'text-orange-500': rowData.color === "orange",
      'text-red-400': rowData.color === "red",
      'text-red-900': rowData.color === "darkred"
    });

    return <div className={btsBodyTemplateClassName}>{rowData.bts_name}</div>;
  };


  const summaryRegionBodyTemplate = (rowData) => {
    const regionBodyTemplateClassName = classNames('font-bold text-sm', {
      'text-red-900': rowData.region === "Душанбе",
      'text-yellow-600': rowData.region === "РРП",
      'text-green-900': rowData.region === "Согд",
      'text-orange-800': rowData.region === "Хатлон (Курган-Тюбе)",
      'text-blue-700': rowData.region === "Хатлон (Куляб)",
    });

    return <div className={regionBodyTemplateClassName}>{rowData.region}</div>;
  };

  const occurTimeBodyTemplate = (rowData) => {
    return (<div className="text-sm"><b>{rowData.occur_time}</b></div>)
  }

  const descriptionBodyTemplate = (rowData) => {
    return (<div className="text-sm"><b>{rowData.description}</b></div>)
  }

  const regionBodyTemplate = (rowData) => {
    return (<div className="text-sm"><b>{rowData.region}</b></div>)
  }

  return (
    <>
      {
        loading ? (
          <div className="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div className="card flex justify-content-center mt-4" style={{ color: 'var(--yellow-600)' }}>
              <h3>{chartData.label}</h3>
            </div>

            <div className="card flex justify-content-center mt-5">
              <Chart type="doughnut" data={chartData.data} options={chartData.options} className="w-full md:w-20rem" />
              <DataTable value={chartData.summaryTable} showGridlines stripedRows size="small">
                <Column field="region" header="Регион" body={summaryRegionBodyTemplate}></Column>
                <Column field="total" header="Всего" body={summaryTableTotalBodyTemplate}></Column>
                <Column field="power" header="Из-за отсутствия электропитания" body={summaryTablePowerBodyTemplate}></Column>
              </DataTable>
            </div>

            <div className="card flex justify-content-center mt-5">
              <DataTable value={apiData.data} showGridlines scrollable scrollHeight="350px" columnResizeMode="fit" stripedRows resizableColumns size="small">
                <Column field="bts_name" header="БС" body={btsBodyTemplate}></Column>
                <Column field="region" header="Регион" body={regionBodyTemplate}></Column>
                <Column field="occur_time" header="Дата" body={occurTimeBodyTemplate}></Column>
                <Column field="description" header="Детали" body={descriptionBodyTemplate}></Column>
              </DataTable>
            </div>
          </>
        )
      }
    </>
  )
}

export default BtsDown