import React from 'react'
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'

const StaffCard = ({ employees }) => {



  const handleSearchChange = (e) => {
    const _employees = employees.filter()
  }


  return (
    <div className='mt-2'>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText placeholder="Search" onChange={handleSearchChange} />
      </IconField>

      <div className='grid mt-1'>
        {employees.map((employee) => {
          return (
            <div key={employee._id} className='sm:col-12 md:col-6 lg:col-4'>
              <Card title={`${employee.lastname} ${employee.firstname}`} subTitle={employee.position} >
                <div className='grid'>
                  <div className='col-8' style={{ backgroundColor: 'blue-500' }}>
                    {employee.division === employee.department
                      ? <>
                        <div> {employee.division} </div>
                        <br />
                      </>
                      : <>
                        <div> {employee.division} </div>
                        <div>{employee.department} </div>
                      </>
                    }
                    <br />
                    <div><i className="pi pi-phone"> {employee.mobile ? <span>+{employee.mobile}</span> : <span>no mobile</span>}</i></div>
                    <div><i className="pi pi-phone"> {employee.vpn}</i></div>
                    <div><i className="pi pi-at style='font-size: 21px; color: green'"> {employee.email ? <span>{employee.email}</span> : <span>no email</span>}</i></div>
                  </div>

                  <div className='col-4'>
                    <Image src={`${employee.image}`} imageClassName="border-circle" alt="Image" width="120" height="120" style={{ align: 'left' }} preview />
                  </div>
                </div>
              </Card>
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}

//<div key={employee._id} className='col-fixed style="width:500px"'>
//<div className='col-fixed style="width:500px"'>
//col-12 md:col-6 lg:col-4
//<div className='col-fixed style="width:250px"'>
//<Image src={`${employee.image}`} imageClassName="border-circle" alt="Image" width="150" height="150" style={{align: 'left'}} preview />


export default StaffCard