import React, { useState, useEffect, useRef } from 'react'
import useAxios from '../../hooks/useAxios'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { useAuth } from '../../contexts/AuthContext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast';

const APIURL = '/api/iplook-cf'

const CallForward = () => {
  const { user } = useAuth()
  const { response, error, setError, loading, fetchData } = useAxios()

  const [msisdn, setMsisdn] = useState(992)
  const [data, setData] = useState(null)
  const [selectedRow, setSelectedRow] = useState({ cftype: '', imsi: '', activated: false, ftn: '' })
  const [editDialog, setEditDialog] = useState(false)

  const options = [{ name: 'Нет', value: false }, { name: 'Да', value: true }]
  const toast = useRef(null);
  const dt = useRef(null);

  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData({ method: 'get', url: APIURL, params: { msisdn: msisdn } })
  }

  useEffect(() => {
    response && setData(response)
    //console.log(response);
  }, [response])

  useEffect(() => {
    data?.status && toast.current.show({ severity: data.status == "error" ? "info" : data.status, detail: data.message, life: 3000 });
  }, [data])

  const handleEditRow = (row) => {
    setSelectedRow(row)
    setEditDialog(true)
  }

  const handleEditDialogSave = () => {
    fetchData({ method: 'post', url: APIURL, 'data': selectedRow })
    data.status === 'success' && setData(prevState => (prevState.data.map(obj => obj.cftype === selectedRow.cftype ? { ...obj, ftn: selectedRow.ftn, activated: selectedRow.activated } : obj)))
    setEditDialog(false)
  }

  const handleEditDialogCancel = () => {
    setEditDialog(false)
  }

  const handleEditDialogChange = (e) => {
    e.target.name === 'ftn' ? setSelectedRow({ ...selectedRow, ftn: e.target.value }) : setSelectedRow({ ...selectedRow, activated: e.value })
  }

  const handleInputChange = (e) => {
    if (e.value === null || e.value.toString().length === 3 && e.value.toString().substr(0, 3) != 992) {
      setMsisdn(992)
    } else {
      setMsisdn(e.value)
    }
  }

  const editDialogFooter = (
    <React.Fragment>
      <div className='p-fluid mr-0'>
        <Button label="Отмена" icon="pi pi-times" outlined onClick={handleEditDialogCancel} className='p-button-warning' />
        <Button label="Сохранить" icon="pi pi-check" onClick={handleEditDialogSave} className='p-button-warning' />
      </div>
    </React.Fragment>
  )

  const editButton = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" severity="warning" onClick={() => handleEditRow(rowData)} />
      </React.Fragment>
    )
  }

  if (!user) return null


  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <form onSubmit={handleFormSubmit}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6">
            <InputNumber value={msisdn} pattern='^[992]{3}[0-9]{9}$' onChange={handleInputChange} placeholder="992XXXXXXXXX" useGrouping={false} />
          </div>
          <div className="col-12 md:col-6">
            <Button label="Поиск" className="p-button-warning w-full" disabled={msisdn.toString().length !== 12} raised />
          </div>
        </div>
      </form>

      {data?.data && (
        <div className='mt-4'>
          <DataTable ref={dt} value={data.data} size='small' showGridlines tableStyle={{ minWidth: '50rem' }}>
            <Column field="imsi" header="IMSI"></Column>
            <Column field="cftype" header="Тип переадресации"></Column>
            <Column field="ftn" header="FTN-номер"></Column>
            <Column field="activated" header="Активирован"></Column>
            <Column field="provided" header="Поддержка"></Column>
            <Column body={editButton} exportable={false} style={{ minWidth: '12rem' }} header="Редактировать"></Column>
          </DataTable>
        </div>
      )}

      <Dialog header="Редактирование" visible={editDialog} modal className="p-fluid" onHide={() => { if (!editDialog) return setEditDialog(false) }} footer={editDialogFooter}>
        <div className="field">
          <label htmlFor="ftn" className="font-bold">FTN-номер</label>
          <InputText id="ftn" name="ftn" value={selectedRow.ftn} onChange={handleEditDialogChange} />
        </div>

        <div className="field">
          <label htmlFor="activated" className="font-bold">Активирован</label>
          <SelectButton id="activated" name="activated" value={Boolean(selectedRow.activated)} onChange={handleEditDialogChange} options={options} optionLabel='name' />
        </div>
      </Dialog >
    </div>
  )
}

export default CallForward