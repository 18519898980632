import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';


const StaffTable = ({ employees, departments }) => {

  const [filters, setFilters] = useState({
    department: { value: null, matchMode: FilterMatchMode.IN },
    division: { value: null, matchMode: FilterMatchMode.IN }
  });


  /**/
  const departmentsItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  

  const footer = `Всего записей: ${employees?.length}`



  const departmentBodyTemplate = (rowData) => {
    const department = rowData.department;

    return (
      <div className="flex align-items-center gap-2">
        <span>{department.name}</span>
      </div>
    );
  };

  const departmentRowFilterTemplate = (options) => {
    return (<MultiSelect value={options.value} options={departments} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Any" className="p-column-filter" scrollHeight='500px' />);
  };

  //body={departmentBodyTemplate}

  return (
    <div className="card mt-2">
      <DataTable value={employees} filters={filters} filterDisplay="row" showGridlines scrollable scrollHeight="flex" stripedRows resizableColumns size="small" footer={footer} dataKey="_id">
        <Column field="lastname" header="Фамилия" style={{ minWidth: '12rem' }} />
        <Column field="firstname" header="Имя" style={{ minWidth: '12rem' }} />
        <Column field="department" header="Сектор" filterField="department" showFilterMenu={false} filter filterElement={departmentRowFilterTemplate} scrollHeight="350px" />

        <Column field="otdel" header="Отдел" style={{ minWidth: '12rem' }} />
        <Column field="doljnost" header="Должность" style={{ minWidth: '12rem' }} />
        <Column field="mobile" header="Мобильный" style={{ minWidth: '12rem' }} />
        <Column field="vpn" header="VPN" style={{ minWidth: '12rem' }} />
        <Column field="email" header="email" style={{ minWidth: '12rem' }} />
        <Column field="sex" header="Пол" style={{ minWidth: '12rem' }} />

      </DataTable>
    </div>

  )

}

export default StaffTable