import { Menubar } from 'primereact/menubar';
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const items = [
    {
      label: 'Запросы',
      icon: 'pi pi-file',
      items: [
        {
          label: 'Приграничные абоненты',
          icon: 'pi pi-users',
          command: () => { navigate('/border-subs') }
        },
        {
          label: 'Местоположение абонента',
          icon: 'pi pi-map-marker',
          command: () => { navigate('/usrinf') }
        },
      ]
    },
    {
      label: 'PCRF Protei',
      icon: 'pi pi-desktop',
      items: [
        {
          label: 'PCRF Protei',
          icon: 'pi pi-desktop',
          items: [
            {
              label: 'Просмотр пакета(ов)',
              command: () => { navigate('/pcrf-protei-packages') }
            },
            {
              label: 'Просмотр пакетов пользователя',
              command: () => { navigate('/pcrf-protei-ordered-packages') }
            },
            {
              label: 'Логи команд',
              icon: 'pi pi-database',
              command: () => { navigate('/pcrf-protei-logs') }
            },
          ],
        },
        {
          label: 'PCRF Protei Test',
          icon: 'pi pi-desktop',
          items: [
            {
              label: 'Просмотр пакетов пользователя',
              command: () => { navigate('/pcrf-protei-ordered-packages-test') }
            },
          ],
        },
        {
          label: 'pcrf logs',
          icon: 'pi pi-database',
          command: () => { navigate('/pcrf-logs') }
        },
        /* {
          separator: true,
        }, */
      ],
    },
    {
      label: 'IP Look',
      icon: 'pi pi-fw pi-desktop',
      items: [
        {
          label: 'Просмотр абонента',
          icon: 'pi pi-fw pi-info-circle',
          command: () => { navigate('/iplook-subdata') }
        },
        {
          label: 'Сервисы абонента',
          icon: 'pi pi-fw pi-th-large',
          command: () => { navigate('/iplook-subsservices') }
        },
        {
          label: 'Переадресации',
          icon: 'pi pi-fw pi-reply',
          command: () => { navigate('/iplook-cf') }
        },
        {
          label: 'Добавление Ki',
          icon: 'pi pi-fw pi-key',
          command: () => { navigate('/iplook-ki') }
        },
      ],
    },
  ];

  user && items.push({
    label: `Выход`,
    icon: 'pi pi-fw pi-power-off',
    url: '/logout'
  })

  return (
    <div>
      <div className="card">
        <Menubar model={items} />
      </div>
      {user.username}
    </div>
  )
}

export default Header;