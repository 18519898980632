import { useEffect, useState, useRef } from "react"
import useAxios from '../../hooks/useAxios'
import { useAuth } from '../../contexts/AuthContext'
import { Button } from 'primereact/button'
import { InputNumber } from "primereact/inputnumber"
import { Fieldset } from "primereact/fieldset"
import { Panel } from "primereact/panel"
import { Checkbox } from "primereact/checkbox"
import { Message } from "primereact/message"
import { Toast } from 'primereact/toast'


function SubsServices() {
  const [msisdn, setMsisdn] = useState(992)
  const [imsi, setImsi] = useState()
  const [ards, setArds] = useState([])
  const [locks, setLocks] = useState({})
  const [initialData, setInitialData] = useState({})
  const [ardsChanged, setArdsChanged] = useState(false)
  const [locksChanged, setLocksChanged] = useState(false)

  const { user } = useAuth()
  const { response, error, setError, loading, fetchData } = useAxios()
  const buttonReference = useRef(null)
  const toast = useRef(null);

  useEffect(() => {
    response?.message && toast.current.show({ severity: response.status, detail: response.message, life: 3000 });
    //console.log(response);
    if (response?.data) {
      setInitialData({ imsi: response.data.imsi, ards: response.data.ards, locks: response.data.locks })
      setLocks(response.data.locks)
      setArds(response.data.ards)
      setImsi(response.data.imsi)
    } else {
      setInitialData({})
      setLocks([])
      setArds({})
      setImsi()
    }
  }, [response])


  const handleInputChange = (e) => {
    if (e.value === null || e.value.toString().length === 3 && e.value.toString().substr(0, 3) != 992) {
      setMsisdn(992)
    } else {
      setMsisdn(e.value)
    }
  }

  const handleSubmitQueryForm = async (e) => {
    e.preventDefault()
    try {
      fetchData({ method: 'get', url: '/api/iplook-subsservices', params: { msisdn: msisdn } })
    } catch (error) {
      console.log(error);
    }
    //setButtonClicked(true)
  }


  useEffect(() => {
    buttonReference.current?.focus()
  }, [msisdn])


  const handleArdChange = (e) => {
    let _ards = [...ards]
    e.checked ? _ards.push(e.value) : _ards.splice(_ards.indexOf(e.value), 1)
    setArds(_ards)
  }

  const handleLockChange = (e) => {
    setLocks({ ...locks, [e.target.name]: e.checked })
  }


  useEffect(() => {
    setLocksChanged(false)
    locks && Object.entries(locks).forEach(([key, value]) => {
      if (initialData.locks[key] !== value) {
        setLocksChanged(true)
        return
      }
    })
  }, [locks])


  useEffect(() => {
    setArdsChanged(false)
    if (ards.length > 0) {
      ards?.forEach(ard => {
        if (!initialData.ards.includes(ard)) {
          setArdsChanged(true)
        }
      })
    }

    initialData.ards?.forEach(ard => {
      if (!ards.includes(ard)) {
        setArdsChanged(true)
      }
    })
  }, [ards])


  const handleSubmitUpdateForm = async (e) => {
    e.preventDefault()
    if (initialData.locks.lockEps === locks.lockEps) delete locks.lockEps
    try {
      fetchData({ method: 'post', url: '/api/iplook-subsservices', data: { imsi: imsi, ards: ards, locks: locks } })
    } catch (error) {
      console.log(error)
    }
  }

  if (!user) return null

  //{response?.status === "error" && <Message severity="error" text={response.message} className="w-full mt-6" />}
  return (
    <>
      <Toast ref={toast} position="top-right" />
      <form onSubmit={handleSubmitQueryForm}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6">
            <InputNumber value={msisdn} pattern='^[992]{3}[0-9]{9}$' onChange={handleInputChange} placeholder="992XXXXXXXXX" useGrouping={false} />
          </div>
          <div className="col-12 md:col-6">
            <Button label="Поиск" className="p-button-warning w-full" disabled={msisdn.toString().length !== 12} raised />
          </div>
        </div>
      </form>

      {imsi && (
        <form onSubmit={handleSubmitUpdateForm}>
          <Panel header="Услуги абонента" className="w-full mt-6">
            <Fieldset legend="Запрет регистрации в сетях">
              <div className="flex flex-wrap gap-4">
                <div className="flex align-items-center">
                  <Checkbox inputId="geranNotAllowed" name="geranNotAllowed" value="geranNotAllowed" onChange={handleArdChange} checked={ards?.includes('geranNotAllowed')} />
                  <label htmlFor="geranNotAllowed" className="ml-2">2G</label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox inputId="utranNotAllowed" name="utranNotAllowed" value="utranNotAllowed" onChange={handleArdChange} checked={ards?.includes('utranNotAllowed')} />
                  <label htmlFor="utranNotAllowed" className="ml-2">3G</label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox inputId="eUtranNotAllowed" name="eUtranNotAllowed" value="eUtranNotAllowed" onChange={handleArdChange} checked={ards?.includes('eUtranNotAllowed')} />
                  <label htmlFor="eUtranNotAllowed" className="ml-2">4G</label>
                </div>
              </div>
            </Fieldset>

            <Fieldset legend="Блокировка услуг" className="w-full mt-6">
              <div className="flex flex-wrap gap-4">
                <div className="flex align-items-center">
                  <Checkbox inputId="lockCsIc" name="lockCsIc" onChange={handleLockChange} checked={locks?.lockCsIc} />
                  <label htmlFor="lockCsIc" className="ml-2">Входящие звонки</label>
                </div>

                <div className="flex align-items-center">
                  <Checkbox inputId="lockCsOg" name="lockCsOg" onChange={handleLockChange} checked={locks?.lockCsOg} />
                  <label htmlFor="lockCsOg" className="ml-2">Исходящие звонки</label>
                </div>

                <div className="flex align-items-center">
                  <Checkbox inputId="lockCsUl" name="lockCsUl" onChange={handleLockChange} checked={locks?.lockCsUl} />
                  <label htmlFor="lockCsUl" className="ml-2">Обновление местоположения в CS</label>
                </div>

                <div className="flex align-items-center">
                  <Checkbox inputId="lockPsUl" name="lockPsUl" onChange={handleLockChange} checked={locks?.lockPsUl} />
                  <label htmlFor="lockPsUl" className="ml-2">Обновление местоположения в PS</label>
                </div>

                <div className="flex align-items-center">
                  <Checkbox inputId="lockEps" name="lockEps" onChange={handleLockChange} checked={locks?.lockEps} />
                  <label htmlFor="lockEps" className="ml-2">Передача данных в сети LTE</label>
                </div>

                <div className="flex align-items-center">
                  <Checkbox inputId="lockPsGprs" name="lockPsGprs" onChange={handleLockChange} checked={locks?.lockPsGprs} />
                  <label htmlFor="lockPsGprs" className="ml-2">Передача данных в сети GPRS</label>
                </div>
              </div>
            </Fieldset>
            <Button label="Сохранить изменения" className="p-button-warning w-full mt-5" raised disabled={ardsChanged === false && locksChanged === false ? true : false} />
          </Panel>
        </form>
      )}
    </>
  )
}

export default SubsServices