import { useState, useEffect, useRef } from "react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { useAuth } from '../../contexts/AuthContext'
import useAxios from "../../hooks/useAxios"

const APIURL = "/api/iplook-ki"

const INITDATA = {
  imsi: 43604,
  ki: '',
  amfId: 1,
  authAlgo: 'milenage',
  authType: '5G_AKA',
  cardType: 'USIM',
  kiEncrypted: false,
  kiK4Id: '',
  milenageId: null,
  opc: '',
  opId: '',
  opcEncrypted: false,
  opcK4Id: '',
  useOpc: false,
  voip: false
}

const AddKi = () => {
  const { response, error, setError, loading, fetchData } = useAxios()
  const [kiData, setKiData] = useState()
  const toast = useRef(null)
  const { user } = useAuth()

  const [formData, setFormData] = useState(INITDATA)

  useEffect(() => {
    fetch('/kiData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setKiData(data)
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData({ method: "post", url: APIURL, data: formData })

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if ((name === "opId" && value !== "") || (name === "opc" && value !== "")) {
      setFormData(prevState => ({ ...prevState, opcEncrypted: false, opcK4Id: '', }))
    }

    if ((name === "useOpc" && value === true)) {
      setFormData(prevState => ({ ...prevState, opId: '', }))
    }

    if (value === "imsi" && value.toString().length === 5 && value.toString().substr(0, 5) != 43604) {
      setFormData(prevState => ({ ...prevState, [name]: 43604 }))
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value.id ? value.id : value }))
    }
  }


  useEffect(() => {
    response?.status && toast.current.show({ severity: response.status, detail: response.message, life: 3000 });
    if (response?.status !== "error") {
      setFormData(INITDATA)
    }
  }, [response])


  if (!user || !kiData) return null

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <form onSubmit={handleFormSubmit}>
        <div className="p-fluid grid mt-5">
          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="imsi"><b>IMSI*</b></label>
              <InputText id="imsi" name="imsi" value={formData.imsi} pattern="^[43604]{5}[0-9]{10}$" onChange={handleInputChange} invalid={response?.message === "Record already exists"} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="ki"><b>Ki*</b></label>
              <InputText id="ki" name="ki" value={formData.ki} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>
        </div>

        <div className="p-fluid grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="useopc"><b>Use OPC</b></label>
              <Dropdown id="useOpc" name="useOpc" value={formData.useOpc} options={[true, false]} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="opc"><b>OPC</b></label>
              <InputText id="opc" name="opc" value={formData.opc} onChange={handleInputChange} disabled={!formData.useOpc} invalid={response?.message === "opc is empty while useOpc is True" && formData.useOpc === true} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="opId"><b>OP</b></label>
              <Dropdown id="opId" name="opId" value={formData.opId} options={kiData.ops} optionLabel="name" optionValue="id" onChange={handleInputChange} disabled={formData.useOpc} invalid={response?.message === "opId is empty" && formData.useOpc === false} scrollHeight="350px" className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="opcEncrypted"><b>OPC K4 Encrypted</b></label>
              <Dropdown id="opcEncrypted" name="opcEncrypted" value={formData.opcEncrypted} options={[true, false]} onChange={handleInputChange} disabled={formData.opId !== 41} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="opcK4Id"><b>OPC K4</b></label>
              <Dropdown id="opcK4Id" name="opcK4Id" value={formData.opcK4Id} options={kiData.kiK4s} optionLabel="name" optionValue="id" onChange={handleInputChange} disabled={!formData.opcEncrypted} scrollHeight="350px" className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="authAlgo"><b>Algorithm</b></label>
              <Dropdown id="authAlgo" name="authAlgo" value={formData.authAlgo} options={kiData.authAlgos} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>
        </div>

        <div className="p-fluid grid mt-1">
          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="kiEncrypted"><b>KI K4 Encrypted</b></label>
              <Dropdown id="kiEncrypted" name="kiEncrypted" value={formData.kiEncrypted} options={[true, false]} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="kiK4Id"><b>K4</b></label>
              <Dropdown id="kiK4Id" name="kiK4Id" value={formData.kiK4Id} options={kiData.kiK4s} optionLabel="name" optionValue="id" onChange={handleInputChange} disabled={!formData.kiEncrypted} scrollHeight="350px" className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="amfId"><b>AMF</b></label>
              <Dropdown id="amfId" name="amfId" value={formData.amfId} options={kiData.amfs} optionLabel="name" optionValue="id" onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="milenageId"><b>Milenage</b></label>
              <Dropdown id="milenageId" name="milenageId" value={formData.milenageId} options={kiData.milenages} optionLabel="name" optionValue="id" onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="authType"><b>Auth Type</b></label>
              <Dropdown id="authType" name="authType" value={formData.authType} options={kiData.authTypes} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <label htmlFor="voip"><b>VoIP</b></label>
              <Dropdown id="voip" name="voip" value={formData.voip} options={[true, false]} onChange={handleInputChange} className="p-inputtext-sm" />
            </div>
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-12">
            <Button label="Добавить" className="p-button-info w-full" disabled={formData.imsi.toString().length !== 15 || formData.ki.length !== 32} raised size="small" />
          </div>
        </div>
      </form>
    </>
  )
}

export default AddKi