import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';

import useAxios from '../../hooks/useAxios';
import { useAuth } from '../../contexts/AuthContext'

const REGIONS = ["Душанбе", "РРП", "Согд", "Хатлон (Куляб)", "Хатлон (Курган-Тюбе)"]


const Alarms = () => {
  const { user } = useAuth();
  const { fetchData, response, error, loading } = useAxios()
  const [alarms, setAlarms] = useState([])
  const [bts, setBts] = useState([])

  const [filters, setFilters] = useState({
    bts: { value: null, matchMode: FilterMatchMode.IN },
    region: { value: null, matchMode: FilterMatchMode.IN }
  });

  useEffect(() => {
    fetchData({ method: 'get', url: '/api/alarms' })
  }, [])


  useEffect(() => {
    if (response !== null) {
      setAlarms(response.data)
      const bts = response.data.map(item => item.bts_name)
      const _bts = Array.from(new Set(bts))
      setBts(_bts)
    }
  }, [response])


  const [departments] = useState([


  ]);




  const footer = `Всего записей: ${response?.data.length}`


  const btsBodyTemplate = (rowData) => {
    const bts = rowData.bts_name;

    return (
      <div className="flex align-items-center gap-2">
        <span>{bts}</span>
      </div>
    );
  };

  //itemTemplate={departmentsItemTemplate}  

  const btsRowFilterTemplate = (options) => {
    return (
      <MultiSelect value={options.value} options={bts} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Any" scrollHeight='500px' />
    );
  };

  const regionRowFilterTemplate = (options) => {
    return (
      <MultiSelect value={options.value} options={REGIONS} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Any" />
    );
  };


  const rowClass = (data) => {
    return {
      'bg-primary': data.category === 'Fitness'
    };
  };



  //body={btsBodyTemplate}
  /* <Column header="Сектор" filterField="department" showFilterMenu={false} body={departmentBodyTemplate} filter filterElement={departmentRowFilterTemplate} />*/
  //paginator rows={1000} rowsPerPageOptions={[100, 500, 1000, 2000, 5000]}

  if (!user) return null

  return (
    <div className="card mt-2">
      <DataTable value={alarms} filters={filters} filterDisplay="row" showGridlines scrollable scrollHeight="750px" columnResizeMode="fit" stripedRows resizableColumns size="small" footer={footer} dataKey="ne_sn" loading={loading}>

        <Column field="ne_sn" header="Serial #" />

        <Column field="bts_name" header="БС" showFilterMenu={false} filterField="bts" filter filterElement={btsRowFilterTemplate} />

        <Column field="alarm_name_rus" header="Авария" />
        <Column field="region" header="Геогр. регион" showFilterMenu={false} filterField="region" filter filterElement={regionRowFilterTemplate} scrollHeight="250px" />
        <Column field="resp_region" header="Отв. регион " />
        <Column field="category" header="Категория" />
        <Column field="occur_time" header="Дата" />
        <Column field="alarm_id" header="ID Аварии" />
        <Column field="description" header="Комментарии" />
        <Column field="location" header="Детали" />
        <Column field="dga_auto" header="Автозапуск ДГА" />
        <Column field="dga_type" header="Модель ДГА" />
        <Column field="redmine_id" header="# Redmine" />
        <Column field="alarm_name_rus" header="Авария" />

      </DataTable>
    </div>

  )

}



export default Alarms